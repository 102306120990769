
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "../../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  name: "Parent-list",
  components: {},
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const filteredNotifications = ref<any[]>([]);
    const notificationsList = ref<any[]>([]);

    const loading = ref<boolean>(true);

    onMounted(async () => {
      ApiService.get("/notifications")
        .then(({ data }) => {
          data = data.map((notif) => {
            if (notif.refType == "newComment") notif.link = "/actualites";
            else notif.link = "/";
            return notif;
          });

          notificationsList.value = data;
          filteredNotifications.value = data;
        })
        .catch((e) => console.log(e))
        .finally(() => {
          loading.value = false;
        });
    });

    return {
      t,
      moment,
      apiUrl: store.getters.serverConfigUrl.base_url + "/",
      loading,
      filteredNotifications,
      notificationsList,
    };
  },
});
